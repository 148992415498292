.shadow:hover {
    cursor: pointer;
    transform: scale(1.02);
  }

  .shadow {
    transition: transform 0.1s linear 0s;
  }

.card {
    border-radius: 0.5rem !important;
    border-width: 3px !important;
}
.tags-expo {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  > * {
    margin: 0;
    margin-top: 8px;
  }
}

.card-deck {
    // align-items: stretch;
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 18rem)!important;
    // margin-bottom: 1rem;
    // display: flex;
    // flex-wrap: wrap;
    // flex-direction: row;
    justify-content: center;
    margin: 0 !important;
}
.icons {
    grid-template-columns: repeat(auto-fill, 14rem)!important;
}

.examples {
    align-items: stretch;
    width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.card-body {
    padding: 0.5rem !important;
    width: 14rem;
    height: 26.5rem;
}

.card-footer {
    text-align: right;
    margin: -1rem;
    margin-top: 0rem;
}

.icon  {
    padding: 0.25rem !important;
    width: 10rem !important;
    height: 15rem !important;

    .card-title {
      font-size: 95%;
    }
}
