@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

.app {
  font-family: "Source Sans Pro";
  font-size: 20px;
}

.video {
  padding-top: 5%;
}

.navbar a, .navbar a:visited {
  color: #eee;
}

.gallery-wrap {
  a.preview:hover {
    text-decoration: none;

    a:hover {
      text-decoration: underline;
    }
  }
}

@media print {
  .printable {
    .no-print {
      display: none;
    }
    .print-page {
      page-break-after: always;
    }
  }
}
