.nav-link.active {
  background-color: #f0f0f0 !important;
  border-color: lightblue !important;
}

.expansion > p {
    padding: 5px;
    border: 1px solid black;
  }

.expansion > .container {
    // padding: 5px;
    border: 1px solid black;
    border-radius: 0.25rem;
  }


.expansion > .container > .row {
    padding: 1rem  !important;

    .mb-5 {
        margin-bottom: 0px  !important;
    }
}

.expansion .icon.card-body {
    padding: 0.25rem !important;
    width: 10rem !important;
    height: 11rem !important;

    .card-title {
        font-size: 1rem;
    }


    .card-img-top {
        width: 80%;
        height: 55%;
        margin-left: 10%;
    }
}
